// Generated by Framer (6597ba8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FHbJJ2AY2", "UxVqpwjYW", "R8RtewYvF"];

const variantClassNames = {FHbJJ2AY2: "framer-v-uvnquy", R8RtewYvF: "framer-v-4s2xxu", UxVqpwjYW: "framer-v-uwsghg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "FHbJJ2AY2", "Variant 2": "R8RtewYvF"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "FHbJJ2AY2", tap: kn9VTUWPT, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "FHbJJ2AY2", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1crm2ls = activeVariantCallback(async (...args) => {
if (kn9VTUWPT) {
const res = await kn9VTUWPT(...args);
if (res === false) return false;
}
setVariant("R8RtewYvF")
})

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2ihPF", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-uvnquy", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"FHbJJ2AY2"} onTap={onTap1crm2ls} ref={ref} style={{backgroundColor: "rgb(51, 170, 255)", ...style}} transition={transition} variants={{R8RtewYvF: {backgroundColor: "rgba(51, 170, 255, 0.42)"}}} {...addPropertyOverrides({R8RtewYvF: {"data-framer-name": "Variant 2", tabIndex: 5}, UxVqpwjYW: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2ihPF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2ihPF * { box-sizing: border-box; }", ".framer-2ihPF .framer-10g7r36 { display: block; }", ".framer-2ihPF .framer-uvnquy { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 152px; }", ".framer-2ihPF.framer-v-4s2xxu .framer-uvnquy { height: 720px; width: 1200px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 152
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UxVqpwjYW":{"layout":["fixed","fixed"]},"R8RtewYvF":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"kn9VTUWPT":"tap"}
 */
const FramerNA5pqU3u0: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerNA5pqU3u0;

FramerNA5pqU3u0.displayName = "Gomb";

FramerNA5pqU3u0.defaultProps = {height: 40, width: 152};

addPropertyControls(FramerNA5pqU3u0, {variant: {options: ["FHbJJ2AY2", "UxVqpwjYW", "R8RtewYvF"], optionTitles: ["Variant 1", "Variant 2", "Variant 2"], title: "Variant", type: ControlType.Enum}, kn9VTUWPT: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerNA5pqU3u0, [])